<template>
  <div>
    <div><system-bar></system-bar> <admin-menu></admin-menu></div>

    <div>
      <v-card>
        <v-card-title>
          <v-btn @click="redirectTo('/admin/users/createLab')">Create LAB User</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="lab">
            <template v-slot:item.actions="{ item }">
              <v-btn @click="redirectTo('/admin/users/edit-lab/' + item._id)"
                >Edit</v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import SystemBar from "../../../library/system-bar/system-bar";
import AdminMenu from "../../menubar/menubar";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "AdminList",
  components: {
    "admin-menu": AdminMenu,
    "system-bar": SystemBar,
  },
  data() {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      headers: [
        {
          value: "email",
          text: "Email",
        },
        {
          value: "fullname",
          text: "Name/Surname",
        },
        {
          value: "status",
          text: "Status",
        },
        {
          value: "actions",
          text: "Actions",
        },
      ],
      lab: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      listLabUser: "BFC/getLabList",
    }),
  },
  methods: {
    ...mapActions({
      setLabList: "BFC/setListLab",
    }),
    redirectTo(path) {
      this.$router.push(path);
    },
    async init() {
      let payload = {
        custId: this.custId,
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/lab/listLabUser",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              //console.log(response)
              await this.setLabList(response.data.data);
              console.log(this.listLabUser);
              this.lab = this.listLabUser.map((item) => {
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.memberId.email,
                  fullname: item.memberId.firstname + " " + item.memberId.lastname,
                  status: item.status,
                  //totalCases: 0,
                  //lastPayment: "-",
                };
                //n++;
              });
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
  },
};
</script>
